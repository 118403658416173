<template>
  <div class="axpEtcContent">
    <div class="axpContent">
      <div class="boothNum">{{ data.booth_no }}</div>
      <!-- <div class="pageNum">0{{ index + 1 }}</div> -->
      <div class="axpInner">
        <div class="header clearfix">
          <div class="company-logo fl">
            <img
                :src="data.logo || defaultLogo"
                class="logoImage"
                alt=""
            />
            <!-- <el-image :src="logo"></el-image> -->
          </div>
          <div class="companyName fl">
            <div class="country textOverflow">
              <img src="../../assets/img/axpEtcLocation.png" alt=""/>
              {{ data.country_name_zh }} {{ data.country_name_en }}
            </div>
            <div class="nameZh textOverflow">
              {{ data.company_name_zh }}
            </div>
            <div class="nameEn textOverflow">
              {{ data.company_name_en }}
            </div>
          </div>
        </div>
        <div class="tagsView clearfix">
          <div
              class="fl tagsItem"
              v-for="(_item, index) in data.bussiess_info"
              :key="index"
          >
            {{ _item.desc_zh }} {{ _item.desc_en }}
          </div>
        </div>
        <div class="profileZh">
          {{ data.profile_zh }}
        </div>
        <div class="profileEn">
          {{ data.profile_en }}
        </div>
        <div class="contactsList clearfix">
          <div
              class="contactItem fl"
              v-for="(item, index) in data.person_list"
              :key="index"
          >
            <div class="nameAndJob textOverflow">
              {{ item.user_name_zh }} {{ item.user_name_en }}
              <span> / {{ item.job_title_zh }} {{ item.job_title_en }}</span>
            </div>
            <div class="mobile textOverflow" v-if="item.mobile">
              手机 MB：<span>{{ item.area_code }} {{ item.mobile }}</span>
            </div>
            <div class="email textOverflow" v-if="item.email">
              邮箱 E-mail：<span>{{ item.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultLogo:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/globalDefaultLogo.png",
      data: {},
    };
  },
  components: {},
  created() {
    this.getJournalInfo();
  },
  methods: {
    async getJournalInfo() {
      let params = {
        company_id: this.$store.state.baseStore.userInfo.company_id, //this.$store.state.baseStore.userInfo.company_id
        meeting_id: 65,
      };
      let res = await this.$store.dispatch(
          "baseConsole/getJournalInfo",
          params
      );
      if (res.success) {
        if (res.data.length != 0) {
          res.data.forEach((item) => {
            item.bussiess_info = this.handleData(
                item.business_classification_info
            );
          });
          this.data = res.data[0];
        }
        console.log(this.data);
      }
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
  },
};
</script>

<style scoped lang="less">
.textOverflow {
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.axpContent {
  width: 1277px;
  height: 1801.5px;
  padding: 15px;
  position: relative;
  margin: 0 auto;

  .boothNum {
    position: absolute;
    right: 15px;
    top: 214px;
    width: 225px;
    height: 40px;
    color: #fff;
    text-align: center;
    background: #066ce8;
    line-height: 40px;
    font-size: 24px;
    font-weight: bold;
  }

  .pageNum {
    font-size: 48px;
    font-weight: bold;
    position: absolute;
    width: 110px;
    height: 110px;
    font-style: italic;
    bottom: 17px;
    left: 88px;
    text-align: center;
    padding-top: 15px;
    color: #fff;
  }

  .axpInner {
    background-image: url("../../assets/img/axpProceedingsBg.33e3e1c1.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    padding: 199px 65px 117px 0px;

    .header {
      position: relative;
      padding-left: 345px;
      height: 121px;

      .company-logo {
        width: 260px;
        height: 260px;
        overflow: hidden;
        position: absolute;
        left: 0px;
        top: -78px;
        display: flex;
        justify-content: center;
        align-items: center;

        .logoImage {
          width: 260px;
          //height: 100%;
        }
      }

      .companyName {
        //margin-top: 20px;
        .country {
          width: 570px;
          display: flex;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
            //position: relative;
            //top: 4px;
            margin-right: 10px;
          }

          font-size: 32px;
          color: #666;
          margin-bottom: 6px;
        }

        .nameZh {
          width: 780px;
          font-size: 32px;
          margin-bottom: 4px;
        }

        .nameEn {
          width: 780px;
          font-size: 32px;
          color: #666;
        }
      }
    }

    .tagsView {
      margin-top: 75px;

      .tagsItem {
        padding: 0px 32px;
        height: 48px;
        border-radius: 48px;
        background: #066ce8;
        margin-right: 22px;
        margin-bottom: 22px;
        color: #fff;
        font-size: 24px;
        line-height: 48px;
      }
    }

    .profileZh {
      margin-top: 20px;
      font-size: 24px;
    }

    .profileEn {
      margin-top: 25px;
      color: #666;
      font-size: 24px;
      word-break: break-all;
      word-wrap: break-word;
    }

    .contactsList {
      margin-top: 50px;

      .contactItem {
        width: 550px;
        margin-bottom: 40px;

        .nameAndJob {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 10px;

          span {
            color: #066ce8;
            font-size: 24px;
            font-weight: normal;
          }
        }

        .mobile,
        .email {
          margin-bottom: 5px;
          font-size: 26px;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
